import { cardPageController } from 'magner';
import { changePass } from 'features/login/changepass/requests';
import { t } from '../ru';

const changePassConfig = cardPageController<{email: string}>({
  getRequest: '',
  createRequest: changePass,

  header: {
    title: t('changePass.title'),
  },
  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: t('changePass.submitBtn'),
        },
      },
    ],
    layout: [
      {
        type: 'input',
        name: 'email',
        props: {
          required: true,
          placeholder: t('changePass.form.email'),
        },
        validation: [
          {
            type: 'email-required' as 'email',
            trigger: 'blur',
          },
          {
            type: 'empty',
            trigger: 'blur',
          },
        ],
      },
    ],
  },
});

export default changePassConfig;
